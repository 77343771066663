#Homepage {
  margin: 0 100px;

  @media screen and (min-width: 2400px) {
    width: 1900px;
    margin: 0 auto;
  }

  @media screen and (max-width: 992px) {
    margin: 0 75px;
  }

  @media screen and (max-width: 775px) {
    margin: 0 50px;
  }

  @media screen and (max-width: 580px) {
    margin: 0 25px;
  }
}
