#Loading {
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgb(21, 29, 34);
  z-index: 11;
  transition-property: opacity;
  transition-timing-function: ease-out;

  .logoContainer {
    width: 55px;
  }

  .animateFlicker {
    animation: flickerAnimation 1s infinite;
  }
}

@keyframes flickerAnimation {
  /* flame pulses */
  0% {
    opacity: 0;
  }
  17% {
    opacity: 0.5;
  }
  25% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media screen and (min-width: 1400px) {
  #Loading .logoContainer {
    width: 75px;
  }
}

@media screen and (max-width: 576px) {
  #Loading .logoContainer {
    width: 35px;
  }
}
