.BlogArticle {
  margin: 64px 0;

  .article-content {
    grid-column-start: span 6;
    h3 {
      margin-bottom: 24px;
    }
  }

  .article-picture {
    grid-column-start: span 6;
    padding: 8px;
  }
}

.BlogArticle:nth-of-type(2n) {
  .article-content {
    grid-column: 7/13;
  }
  .article-picture {
    grid-column: 1/6;
  }
}
