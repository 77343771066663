#Contact {
  .contactContainer {
    margin-top: 20vh;
    margin-bottom: 50px;
    padding: 25px 50px;
    width: 100%;
    border-radius: 25px 25px 0 0;
    // color: #1e124d;
  }

  label {
    width: 30%;
    line-height: 2.5rem;
  }

  input,
  button {
    outline: none;
    border: none;
    color: white;
  }
  input {
    font-family: "Futura Bk";

    margin-left: 10px;
    padding-left: 5px;
    background-color: transparent;
    border-bottom: 1px solid white;
    width: 70%;
    font-size: 1.5rem;
  }

  .instance {
    margin: 44px 0 7px 0;
    font-size: 1.5rem;
  }

  button {
    margin-top: 15px;
    border-radius: 7px;
    cursor: pointer;
    border: solid 1px white;
    background-color: transparent;
    color: white;
    padding: 5px 10px;
    transition: all 0.2s ease-out;
    &:hover {
      background-color: white;
      color: #1e124d;
    }
  }

  @media screen and (max-width: 772px) {
    h2 {
      font-size: 3rem;
      line-height: 4rem;
    }
    form label,
    form input {
      font-size: 1rem;
      line-height: 1.7rem;
    }
  }

  @media screen and (max-width: 590px) {
    .contactContainer {
      padding: 12px 30px;
    }

    h2 {
      font-size: 2rem;
      line-height: 2.3rem;
    }

    form label,
    form input {
      font-size: 0.8rem;
    }

    form textarea {
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 400px) {
    h2 {
      font-size: 1.5rem;
      line-height: 1.7rem;
    }
  }
}
