.Project {
  margin-bottom: 200px;
  flex-direction: row-reverse;

  .titleContainer {
    height: 100%;
    // overflow: hidden;
  }

  .projectTitle {
    width: 20vw;
    font-size: 4rem;
    z-index: 1;
    bottom: 20px;
    left: 20px;
    bottom: 10vh;
    line-height: 4rem;
  }

  .projectPicture {
    width: 55vw;
    overflow: hidden;
    cursor: pointer;
    img {
      transform: scale(1.2);
    }
  }
}

.Project:nth-child(2n + 1) {
  flex-direction: row;

  .projectTitle {
    left: 35vw;
  }
}

.Project:last-of-type {
  margin-bottom: 50px;
}

@media screen and (min-width: 1470px) {
  .Project {
    height: 60vh;
    .titleContainer h2 {
      font-size: 5rem;
      line-height: 5rem;
    }
  }
}

@media screen and (min-width: 1675px) {
  .Project {
    margin: 0 100px;
    .titleContainer h2 {
      font-size: 6rem;
      line-height: 6rem;
    }
  }
}
@media screen and (min-width: 1900px) {
  .Project .titleContainer h2 {
    font-size: 6.6rem;
    line-height: 6.6rem;
  }
}
@media screen and (max-width: 1100px) {
  .Project {
    margin-bottom: 150px;
    .titleContainer h2 {
      font-size: 3.5rem;
      line-height: 3.5rem;
    }
  }
}
@media screen and (max-width: 992px) {
  .Project {
    height: auto;
    margin-bottom: 100px;
    .titleContainer h2 {
      font-size: 3rem;
      line-height: 3rem;
    }
  }
}
@media screen and (max-width: 830px) {
  .Project {
    .titleContainer h2 {
      font-size: 2.5rem;
      line-height: 2.5rem;
    }
  }
}

@media screen and (max-width: 772px) {
  .Project {
    .projectTitle {
      width: 15vw;
    }

    .projectPicture {
      width: 65vw;
    }

    .titleContainer h2 {
      font-size: 2rem;
      line-height: 2rem;
    }
  }
}

@media screen and (max-width: 576px) {
  .Project {
    padding: 0;
    margin-bottom: 50px;

    .projectPicture {
      width: 100%;
    }
    .titleContainer .projectTitle {
      width: 100%;
    }

    .titleContainer h2 {
      font-size: 1.5rem;
    }
  }
}

@media screen and (max-width: 390px) {
  .Project {
    margin-bottom: 30px;
    .titleContainer h2 {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
}
