#About {
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
  margin: 0 100px;

  .picture {
    height: 130px;
    width: 130px;
    bottom: 0;
    overflow: hidden;
    transform: translate(2px, 0), rotate(20);
  }

  .picture.round {
    border-radius: 50%;
  }

  .textContainer {
    height: 65vh;
  }

  .bottomParagraphContainer {
    margin: 50px 0 20px 0;

    p {
      line-height: 2rem;
    }
  }
}

@media screen and (min-width: 1440px) {
  #About {
    h1 {
      width: 700px;
    }

    .picture {
      width: 150px;
      height: 150px;
    }
    .textContainer h2 {
      font-size: 4rem;
      line-height: 7rem;
    }

    .bottomParagraphContainer {
      font-size: 1.5rem;
    }
  }
}

@media screen and (min-width: 1700px) {
  #About {
    .picture {
      width: 200px;
      height: 200px;
    }

    .bottomParagraphContainer {
      font-size: 1.5rem;
    }
  }
}

@media screen and (min-width: 1900px) {
  #About {
    width: 1700px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1200px) {
  #About {
    .textContainer h2 {
      font-size: 3.5rem;
    }
    .picture {
      width: 110px;
      height: 110px;
    }
  }
}

@media screen and (max-width: 992px) {
  #About {
    .textContainer {
      height: 55%;
      h2 {
        font-size: 3rem;
      }
    }
  }
}

@media screen and (max-width: 930px) {
  #About {
    .textContainer h2 {
      font-size: 2.5rem;
    }
  }
}

@media screen and (max-width: 845px) {
  #About {
    .picture {
      width: 90px;
      height: 90px;
    }
  }
}

@media screen and (max-width: 775px) {
  #About {
    height: auto;
    margin: 100px 75px 50px 75px;
    overflow: hidden;

    h2 {
      font-size: 3.2rem;
      line-height: 4rem;
    }
  }
}

@media screen and (max-width: 605px) {
  #About {
    margin: 100px 24px 24px 24px;

    h2 {
      font-size: 2rem;
      line-height: 110%;
    }
  }
}

@media screen and (max-width: 475px) {
  #About {
    .bottomParagraphContainer p {
      font-size: 1rem;
    }
  }
}

@media screen and (max-width: 376px) {
  #About {
    h2 {
      font-size: 1.7rem;
      line-height: 2.3rem;
    }
    .bottomParagraphContainer p {
      font-size: 0.8rem;
    }
  }
}
