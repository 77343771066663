.articlesBg {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
}

#Blog {
  padding: 100px 50px 0;
  .articlesBg {
    z-index: -1;
  }

  h2 {
    margin-bottom: 24px;
  }
}
