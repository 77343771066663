#ProductPresentation {
  background-color: white;
  color: black;
  height: calc(100vh - 70px);
  width: 100%;
  overflow-y: scroll;
  position: fixed;
  top: 70px;

  .hero {
    height: calc(57vh - 70px);
    padding: 54px 100px 60px 100px;

    p {
      font-size: 1.15rem;
      font-family: "Futura Light";
    }

    .underline {
      font-family: "Futura Light";
    }

    .bio {
      grid-column-start: span 6;
      line-height: 146%;
    }

    .infos {
      grid-column: 8 / -1;
      p {
        margin-top: 10px;
      }
    }
  }

  .image {
    height: 43vh;
    display: block;
    width: 100%;
  }

  .main {
    margin: 80px 100px 0 100px;
    .legende {
      grid-column: 1 / 4;
      font-family: "Futura Light";
      font-size: 0.9rem;
      line-height: 145%;
    }
    .figure {
      grid-column: 5 / -1;
      margin-bottom: 100px;
    }
  }

  .footer {
    padding: 0 100px 30px 100px;
    .conclusion {
      font-family: "Futura Bk";
      font-size: 1.4rem;
      line-height: 150%;
      margin-bottom: 32px;
    }
    .line {
      width: 100%;
      height: 1px;
      background-color: black;
      margin-bottom: 56px;
    }

    .buttons {
      p {
        margin: 0 15px;
        font-size: 1.15rem;
        font-family: "Futura Light";
        cursor: pointer;
      }
      .smallLine {
        height: 1px;
        width: 40px;
        background-color: black;
      }
    }
  }

  // Media queries baby
  @media screen and (min-width: 1400px) {
    h1 {
      font-size: 5rem;
    }
    .hero {
      width: 1280px;
      margin: 0 auto;
      padding: 56px 0;

      p {
        font-size: 1.5rem;
      }
    }

    .main {
      width: 1280px;
      margin: 80px auto;

      .legende {
        font-size: 1.3rem;
      }
    }

    .footer {
      width: 1280px;
      margin: 30px auto;
      padding: 0;
    }
  }

  @media screen and (max-width: 992px) {
    h1 {
      font-size: 3rem;
    }

    .hero {
      p {
        font-size: 1.05rem;
      }
    }
  }

  @media screen and (max-width: 775px) {
    h1 {
      font-size: 2.5rem;
    }

    .hero {
      padding: 56px 56px 60px 56px;

      .bio {
        grid-column-start: span 12;
        margin-bottom: 10px;
      }

      .infos {
        grid-column-start: span 12;
      }
    }

    .main {
      margin: 64px 56px 0 56px;
      .legende {
        grid-column: 1/-1;
        order: 2;
        margin-bottom: 64px;
      }
      .figure {
        grid-column-start: span 12;
        margin-bottom: 5px;
        order: 1;
      }
    }

    .footer {
      padding: 0 56px 30px 56px;
      .conclusion {
        font-size: 1.2rem;
      }
    }
  }

  @media screen and (max-width: 580px) {
    h1 {
      font-size: 2rem;
    }

    .hero {
      padding: 32px 8px 40px 8px;
    }

    .main {
      margin: 32px 8px 0 8px;
    }

    .footer {
      padding: 0 8px 32px 8px;

      .conclusion {
        font-size: 1rem;
      }
    }
  }

  @media screen and (max-width: 425px) {
    h1 {
      font-size: 1.5rem;
    }

    .main {
      margin: 32px 8px 0 8px;
    }

    .footer {
      padding: 0 8px 32px 8px;

      .conclusion {
        font-size: 0.9rem;
      }

      .line {
        margin-bottom: 32px;
      }

      .buttons p {
        font-size: 1rem;
      }
    }
  }
}
