// Basic set up
* {
  overscroll-behavior: none;
  font-family: "Futura Bk", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "MonumentExtended";
}

body {
  background-color: #151d22;
  color: rgb(240, 230, 230);
}

a {
  text-decoration: none;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  pointer-events: auto;
  color: inherit;
  font-family: inherit;
  cursor: pointer;
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 3.5rem;
}
