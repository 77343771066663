#ScrollIndicator {
  bottom: 30px;
  right: 20px;
  cursor: pointer;
  transition: transform 0.2s ease-out;
  transform: scale(1.1);

  p span {
    font-family: "Futura Bk";
    margin-bottom: 2px;
  }

  .scrollLine {
    opacity: 0;
    width: 1px;
    height: 50px;
    border-radius: 12px;
    background-color: white;
  }
}

@media screen and (min-width: 1400px) {
  #ScrollIndicator {
    right: 40px;
    bottom: 50px;
  }
}
