#SelectedWorks {
  margin-bottom: 100px;
  .mainTitle {
    transform: translate(0, 0);
  }
}

@media screen and (max-width: 992px) {
  #SelectedWorks .mainTitle {
    font-size: 3rem;
    line-height: 3rem;
  }
}

@media screen and (max-width: 768px) {
  #SelectedWorks .mainTitle {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
}

@media screen and (max-width: 576px) {
  #SelectedWorks .mainTitle {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 50px;
  }
}
