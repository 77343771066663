@font-face {
  font-family: "MonumentExtended";
  src: url("../../Assets/Fonts/MonumentExtended-Regular.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Futura Bk";
  src: url("../../Assets/Fonts/Futura Book font.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Futura Light";
  src: url("../../Assets/Fonts/futura\ light\ bt.ttf") format("truetype");
  font-weight: 400;
}
