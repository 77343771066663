#Navbar {
  height: 70px;
  z-index: 10;
  width: 100%;
  top: 0;
  left: 0;
  padding: 10px 100px;
  transition: backdrop-filter ease-out 0.5s;

  .navbarContainer {
    height: 100%;
  }

  .logoContainer {
    width: 9px;
    transform: translate(0, -80px);
  }
}

#Navbar:hover {
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

/* Add the blur effect */
.blur {
  opacity: 1;
  top: -70px;
  left: 0;
  height: 80px;
  width: 100%;
}

@media screen and (min-width: 1900px) {
  #Navbar {
    .navbarContainer {
      width: 1700px;
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 775px) {
  #Navbar {
    padding: 10px 50px;
  }
}

@media screen and (max-width: 625px) {
  #Navbar .navLinks {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  #Navbar {
    padding: 10px 25px;
  }
}
